@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
:root{
    --gap: 5px;
}

body{
    background-color: #00537a;
    background-image: url("https://www.transparenttextures.com/patterns/food.png");
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

::-webkit-scrollbar{
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-thumb{
    background-color: rgba(189, 189, 189, 0.8);
    border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover{
    background-color: rgba(189, 189, 189, 1);
}
::-webkit-scrollbar-track{
    background-color: transparent;
}
.outer-container{
}

#title{
    font-family: 'Gloria Hallelujah', cursive;
    -webkit-user-select: none;
            user-select: none;
}

.title-span{
    position: relative;
    display: inline-block;
    /* animation: rotate 0.5s linear infinite alternate; */
}
.title-span::after{
    content: attr(data-content);
    position: absolute;
    left: 5px;
    z-index: -1;
    color: black;
    animation: shake 0.25s steps(2) infinite alternate forwards;
}

.form-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

#login-form{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 5px;
    background-color: white;
}

#login-form input{
    padding: 1rem;
    font-size: 15px;
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
}

#login-form input:focus{
    border: 1px solid #00537a;
}

#play{
    background-color: #00537a;
    border:none;
    border-radius: 5px;
    padding: 1rem;
    color: white;
    cursor: pointer;
}

.mt-10{
    margin-top: 10px;
}

#error{
    color: red;
}

@keyframes rotate {
    0%{
        transform: rotate(-5deg);
    }
    100%{
        transform: rotate(5deg);
    }
}

@keyframes shake {
    0%{
        left: -5px;
    }
    100%{
        left: 5px;
    }
}
nav{
    position: -webkit-sticky;
    position: sticky;
    left: 0;
}

.outer-container{
    overflow-y: hidden;
    min-height: 100vh;
    margin-bottom: 10px;
}

.center-wait{
    display: flex;
    align-items: center;
    justify-content: center;
}

.wait{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1rem;
    background-color: white;
    border-radius: 5px;
}

.word-bar{
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    border-radius: 5px;
    width: 100%;
    margin: 10px auto;
}
.word-bar h1{
    display: inline-block;
    margin: 0;
    padding: 10px;
    font-size: 1.5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.word{
    position: relative;
    overflow-wrap: break-word;
}
.timer{
    position: absolute;
    left: 0;
}
.rounds{
    position: absolute;
    right: 0;
}

.canvas-outer-container{
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    height: 90vh;
}

.canvas-container{
    min-width: 400px;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.canvas-options-outer-container{
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.word-options-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 15px;
    border-radius: 5px;
}

.word-option{
    background: rgba(1,1,1,0.5);
    border: none;
    margin: 15px;
    padding: 10px;
    border-radius: 5px;
    color: white;
}
.word-option:hover{
    background: rgba(1,1,1,0.7);
    cursor: pointer;
}

.card-flexed{
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 500px) {
    .rounds-info-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .timer{
        position: relative;
    }
    .rounds{
        position: relative;
    }
}
.scores-container{
    min-width: 200px;
    width: 25vw;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    overflow-y: auto;
    border-radius: 5px;
}
.card-container{
    width: 100%;
    margin-bottom: 2px;
    background: white;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.card-container h3{
    margin:0;
    padding: 5px;
}

#user-name{
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 5px;
    color: rgb(78, 78, 78);
}

#user-score{
    color: green;
}
.guess-container{
    min-width: 200px;
    width: 25vw;
    background-color: white;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.guess-list{
    height: 100%;
    overflow-y: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.input-container{
    height: 2.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
}

.guess-input{
    width: 100%;
    outline: none;
    padding: 0;
    padding-left: 10px;
    height: 2rem;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid gray;
}
.score-board{
    overflow-y: auto;
    background-color: white;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
}
.scores{
    text-align: center;
}
.score-card{
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    border-radius: 10px;
    justify-content: space-around;
}
.score-card h1{
    margin: 10px;
    font-weight: 200;
}
.mr-20{
    margin-right: 20px;
}

.ml-20{
    margin-left: 20px;
}

#canvas{
    /* cursor: url('./Cursor/edit5.svg') 5 5, auto; */
    border-radius: 5px;
}

.options-outer-container{
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    overflow-x: auto;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.options-container{
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    padding: 5px;
    margin: auto 0.5em;
}
.option-icon{
    border: 1px solid black;
    border-radius: 50%;
    padding: 0.5em;
    margin: 0.2em;
    cursor: pointer;
}
.option-icon:hover{
    background-color: black;
    color: white;
}
.option-icon-active{
    background-color: black;
    color: white;
}
.color-box{
    width: 1.2em;
    height: 1.2em;
    margin: 2px;
    border: 1px solid black;
    display: inline-block;
    cursor: pointer;
    transition: 0.2s ease;
}
.color-box:hover{
    transform: scale(1.2);
    transition: 0.2s ease;
}
.color-box-active{
    transform: scale(1.2);
    transition: 0.2s ease;
}
.color-box-active::after{
    content: '✔️';
    font-size: 0.7em;
    position: absolute;
    left: 0;
    top: 0;
}

@media screen and (max-width : 650px){
    .options-outer-container{
        justify-content: flex-start;
    }
}
