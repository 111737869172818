nav{
    position: sticky;
    left: 0;
}

.outer-container{
    overflow-y: hidden;
    min-height: 100vh;
    margin-bottom: 10px;
}

.center-wait{
    display: flex;
    align-items: center;
    justify-content: center;
}

.wait{
    width: fit-content;
    padding: 1rem;
    background-color: white;
    border-radius: 5px;
}

.word-bar{
    position: sticky;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    border-radius: 5px;
    width: 100%;
    margin: 10px auto;
}
.word-bar h1{
    display: inline-block;
    margin: 0;
    padding: 10px;
    font-size: 1.5em;
    width: fit-content;
}
.word{
    position: relative;
    overflow-wrap: break-word;
}
.timer{
    position: absolute;
    left: 0;
}
.rounds{
    position: absolute;
    right: 0;
}

.canvas-outer-container{
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    height: 90vh;
}

.canvas-container{
    min-width: 400px;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.canvas-options-outer-container{
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.word-options-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 15px;
    border-radius: 5px;
}

.word-option{
    background: rgba(1,1,1,0.5);
    border: none;
    margin: 15px;
    padding: 10px;
    border-radius: 5px;
    color: white;
}
.word-option:hover{
    background: rgba(1,1,1,0.7);
    cursor: pointer;
}

.card-flexed{
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 500px) {
    .rounds-info-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .timer{
        position: relative;
    }
    .rounds{
        position: relative;
    }
}