.mr-20{
    margin-right: 20px;
}

.ml-20{
    margin-left: 20px;
}

#canvas{
    /* cursor: url('./Cursor/edit5.svg') 5 5, auto; */
    border-radius: 5px;
}